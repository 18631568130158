import './bit.js';
import 'magnific-popup'; 
import 'owl.carousel';

jQuery( document ).ready(function($) {
    $('#dates').bit({
        'artist': 'Ryan Ellis',
        limit       : 5,
        template    : $(`
            <div class="event">
                <div>
                    <div class="date">
                        <span class="dow"></span>,
                        <span class="month"></span>
                        <span class="day"></span>
                        <span class="year"></span>
                    </div>
                    <div class="venue"></div>
                    <div class="location">
                        
                        <span class="city">,</span>
                        <span class="region">,</span>
                        <span class="country"></span>
                    </div>
                </div>
                <div class="tickets"></div>
            </div>
            `),
        show_dow    : true,
        format_dow  : 'ddd',
        btns_order  : ['rsvp', 'tickets'],
        show_y      : false,
        more_msg    : 'View More Dates',
        less_msg    : 'View Less Dates',
        tickets_msg : 'Tickets',
        show_vip    : false,
        show_rsvp   : true
    }); 

    $('#music-carousel').owlCarousel({
        loop:true,
        items: 1,
        margin: 20,
        dots: false,
        nav: true,
        navText: [
          "<i class='fas fa-arrow-left'></i><span class='sr'>Prev</span>",
          "<i class='fas fa-arrow-right'></i><span class='sr'>Next</span>"
        ]
    });

    $('#video-carousel').owlCarousel({
        loop:true,
        items: 4,
        margin: 20,
        dots: false,
        nav: true,
        navText: [
          "<i class='fas fa-arrow-left'></i><span class='sr'>Prev</span>",
          "<i class='fas fa-arrow-right'></i><span class='sr'>Next</span>"
        ],
        responsive:{
            0:{
             items: 2   
            },
            800:{
                items: 4
            }
        }
    });

    $("#video-carousel").on("click"," .item a", function(e) {
        e.preventDefault();
        var id = $(this).attr("data-id");
        $("#video iframe").attr(
            "src",
            "https://www.youtube.com/embed/" + id + "/?autoplay=1"
        );
        $(".main-video-title span").text($(this).attr("data-title"));
    });

    $( '.page-template-homepage .scroll a' ).on( 'click', function ( e ) {
        e.preventDefault();
        const href = '#' + $( this ).attr( 'href' ).split('#')[1];
        $( 'html, body' ).animate({
            scrollTop: $( href ).offset().top,
        },500);
    } );

    $('#menu-toggle').on('click', function(e){
        e.preventDefault();
        $("#menu").toggleClass('open');
        $("#menu-toggle").toggleClass('open');
    })

    $('#menu a').on('click', function(){
        $('#menu').removeClass('open');
        $("#menu-toggle").removeClass('open');
    });

    $('.section-content .chord').hide();
    $('.section-content .chord:first-child').show();

    $('#chord-select').on('change', function(){
        $('.section-content .chord').hide();
        let value = $('#chord-select').val();
        $(value).show();
    })

    
	$( '.mfp-image' ).magnificPopup( {
		type: 'image'
	} );
});